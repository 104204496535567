import React from "react";

export default function ExpiredDomain() {
  return (
    <div>
      This hosting platform has expired. Please renew your subscription to
      proceed
    </div>
  );
}
